<template>
  <div class="theme_one_content_container">
    <router-link
      :to="{
          name: 'course-module',
          params: { slug: slug, module: moduleId }
        }"
      class="theme_one_content_container_btn_back">
      <div class="theme_one_content_container_btn_text">Назад</div>
    </router-link>
    <div class="theme_one_plot">
      <div
        :class="{
          'theme_one_plot_theme_active': theme.id === moduleTheme.id,
          'theme_one_plot_theme': theme.id !== moduleTheme.id
        }"
        v-if="module && module.themes"
        v-for="moduleTheme in module.themes"
      >
        <div class="w-100" v-if="theme.id === moduleTheme.id">
          <div class="theme_one_plot_theme_active_label">{{ moduleTheme.name }}</div>
          <div
            v-for="(material, key) in theme.materials"
            @click="materialId = key;materialKey++"
            class="theme_one_plot_theme_item"
            :class="{
              active: key === materialId,
              video: material.type === 'video',
              audio: material.type === 'audio',
              document: material.type === 'document',
              presentation: material.type === 'presentation'
            }"
          >{{ material.title }}</div>
          <router-link
              :to="{ name: 'course-exercise', params: { slug: slug, quiz: quiz.id } }"
              class="theme_one_plot_theme_item exercise"
              v-for="quiz in moduleTheme.quizzes">
            {{ quiz.title }}
          </router-link>
        </div>
        <span v-else>{{ moduleTheme.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChapterNavigation",
  props: [
    'slug',
    'moduleId',
    'module',
    'theme',
    'materialId'
  ]
}
</script>

<style scoped>

</style>