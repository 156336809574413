import { render, staticRenderFns } from "./CourseTheme.vue?vue&type=template&id=586e6774&scoped=true&"
import script from "./CourseTheme.vue?vue&type=script&lang=js&"
export * from "./CourseTheme.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586e6774",
  null
  
)

export default component.exports