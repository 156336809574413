<template>
  <div class="theme_one_exercises_desc">
    <div class="theme_one_exercises_desc_text_container">
      <div class="theme_one_desc_title">{{ material.title }}</div>
      <div class="theme_one_desc_text" v-html="material.title"></div>
      <div class="theme_one_desc_essay pb-1" v-html="material.text"></div>
      <div class="p-5">
        <a
          v-if="material.document_path"
          target="_blank"
          class="theme_one_desc_download"
          :href="material.document_path"
          >Скачать документ</a
        >
      </div>
      <div
        v-if="material.attachments && material.attachments.length"
        class="pb-5"
      >
        <a
          target="_blank"
          class="theme_one_desc_download"
          v-for="attachment in material.attachments"
          :href="attachment.url"
          >{{ attachment.link_name }}</a
        >
      </div>
      <div class="theme_one_desc_btn">
        <a
          v-if="showPrev"
          @click="prev"
          class="theme_one_desc_btn_back_with_arrow"
          >Назад</a
        >
        <a v-if="showNext" @click="next" class="theme_one_desc_btn_next"
          >Далее</a
        >
        <a
          v-if="showNextTheme"
          @click="$emit('goToNextModule')"
          class="theme_one_desc_btn_next"
        >
          Следующая тема
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentMaterial",
  props: ["material", "showNext", "showPrev", "showNextTheme"],
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style scoped></style>
