var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme_one_block"},[_c('b-modal',{attrs:{"id":"show","hide-footer":"","hide-header":"","centered":"","size":"lg"}},[_c('div',{staticClass:"store__product__modal",staticStyle:{"background":"#f7e9f6","color":"#c460c0"},attrs:{"id":"dialog"}},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$bvModal.hide('show')}}},[_c('span',{attrs:{"aria-hidden":"true"}})]),_c('h1',[_vm._v("Вы успешно завершили курс")]),_c('br'),_vm._v(" На ваш счет начислено 25 коинов ")])]),_c('div',{staticClass:"theme_one_header_block"},[_c('div',{staticClass:"theme_one_header_container"},[_c('div',{staticClass:"theme_one_header_label"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")])])]),_c('div',{staticClass:"theme_one_main_block"},[_c('div',{staticClass:"theme_one_main_container"},[_c('div',{staticClass:"theme_one_content_container"},[_c('router-link',{staticClass:"theme_one_content_container_btn_back",attrs:{"to":{
            name: 'course-module',
            params: {
              slug: _vm.$route.params.slug,
              module: _vm.$route.params.module,
            },
          }}},[_c('div',{staticClass:"theme_one_content_container_btn_text"},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")])]),_c('div',{staticClass:"theme_one_plot"},[_c('div',{staticClass:"theme_one_plot_theme_active"},[_c('div',{staticClass:"theme_one_plot_theme_active_label pr_40",class:{
                theme_one_plot_theme_active_label_active: _vm.showTheme === true,
              },on:{"click":function($event){_vm.showTheme = !_vm.showTheme}}},[_vm._v(" "+_vm._s(_vm.currentTheme.name)+" ")]),_vm._l((_vm.currentTheme.materials),function(material,key){return (_vm.showTheme)?_c('div',{staticClass:"theme_one_plot_theme_item",class:{
                active: key === _vm.currentMaterialId,
                video: material.type === 'video',
                audio: material.type === 'audio',
                document: material.type === 'document',
                presentation: material.type === 'presentation',
                disabled: !material.available,
              },on:{"click":function($event){return _vm.switchMaterial(key)}}},[_vm._v(" "+_vm._s(material.title)+" ")]):_vm._e()}),_vm._l((_vm.currentTheme.quizzes),function(quiz){return (_vm.showTheme)?_c('div',{staticClass:"theme_one_plot_theme_item exercise",class:{
                active: quiz.id === _vm.quizId,
                disabled: !quiz.available,
              },on:{"click":function($event){return _vm.openQuiz(quiz.id, quiz.available)}}},[_vm._v(" "+_vm._s(quiz.title)+" ")]):_vm._e()})],2)]),(_vm.currentModule && _vm.currentModule.quizzes)?_c('div',{staticClass:"theme_one_plot"},[_c('div',{staticClass:"theme_one_plot_theme_active"},[_c('div',{staticClass:"theme_one_plot_theme_active_label"},[_vm._v(" "+_vm._s(_vm.$t("Testing"))+" ")]),_vm._l((_vm.currentModule.quizzes),function(quiz){return _c('router-link',{staticClass:"theme_one_plot_theme_item exercise",staticStyle:{"text-decoration":"none"},attrs:{"to":{
                name: 'course-exercise',
                params: { slug: _vm.$route.params.slug, quiz: quiz.id },
              }}},[_vm._v(" "+_vm._s(quiz.title)+" ")])})],2)]):_vm._e()],1),(_vm.checkMaterial('video'))?_c('VideoMaterial',{key:_vm.materialKey,attrs:{"showPrev":_vm.showPrev,"showNext":_vm.showNext,"show-next-theme":_vm.showNextTheme,"material":_vm.currentMaterial},on:{"prev":_vm.prev,"next":_vm.next,"goToNextModule":_vm.goToNextModule}}):_vm._e(),(_vm.checkMaterial('presentation'))?_c('PresentationMaterial',{key:_vm.materialKey,attrs:{"showPrev":_vm.showPrev,"showNext":_vm.showNext,"show-next-theme":_vm.showNextTheme,"material":_vm.currentMaterial},on:{"prev":_vm.prev,"next":_vm.next,"goToNextModule":_vm.goToNextModule}}):_vm._e(),(_vm.checkMaterial('text'))?_c('DocumentMaterial',{key:_vm.materialKey,attrs:{"showPrev":_vm.showPrev,"showNext":_vm.showNext,"show-next-theme":_vm.showNextTheme,"material":_vm.currentMaterial},on:{"prev":_vm.prev,"next":_vm.next,"goToNextModule":_vm.goToNextModule}}):_vm._e(),(_vm.checkMaterial('document'))?_c('DocumentMaterial',{key:_vm.materialKey,attrs:{"showPrev":_vm.showPrev,"showNext":_vm.showNext,"show-next-theme":_vm.showNextTheme,"material":_vm.currentMaterial},on:{"prev":_vm.prev,"next":_vm.next,"goToNextModule":_vm.goToNextModule}}):_vm._e(),(_vm.checkMaterial('audio'))?_c('AudioMaterial',{key:_vm.materialKey,attrs:{"showPrev":_vm.showPrev,"showNext":_vm.showNext,"show-next-theme":_vm.showNextTheme,"material":_vm.currentMaterial},on:{"prev":_vm.prev,"next":_vm.next,"goToNextModule":_vm.goToNextModule}}):_vm._e(),(_vm.quizId)?_c('ExerciseMaterial',{key:_vm.quizKey,attrs:{"slug":_vm.$route.params.slug,"quiz-id":_vm.quizId},on:{"goToNextModule":_vm.goToNextModule,"reload":_vm.reloadQuiz}}):_vm._e(),(!_vm.quizId && _vm.currentTheme && !_vm.existsMaterials)?_c('div',{staticClass:"theme_one_exercises_desc"},[_c('div',{staticClass:"theme_one_exercises_desc_text_container"},[_c('div',{staticClass:"theme_one_desc_title"},[_vm._v(_vm._s(_vm.$t("Nothing_found")))])])]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }