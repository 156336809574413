<template>
  <div class="theme_one_exercises_desc">
    <div
      class="theme_one_exercises_desc_text_container"
      v-if="!(error && success)"
    >
      <div class="theme_one_modal_save_result" v-if="success">
        <div class="theme_one_modal_result_text">
          {{ $t("Response_saved_successfully") }}
        </div>
        <div class="theme_one_modal_result_img"></div>
      </div>
      <div
        class="theme_one_modal_result_bad"
        v-if="finished && quiz.mode == 'ONLY_RAW_ANSWERS'"
      >
        <div class="theme_one_modal_bad_text_block">
          <div class="theme_one_modal_bad_result_text w-100">
            <h5>{{ $t("endTask") }}!</h5>
          </div>
          <div
            class="theme_one_modal_bad_result_btn pointer"
            @click="finished = false"
          >
            Закрыть
          </div>
        </div>
      </div>
      <div
        class="theme_one_modal_result_bad"
        v-if="
          finished &&
          result < quiz.passing_score &&
          quiz.mode != 'ONLY_RAW_ANSWERS'
        "
      >
        <div class="theme_one_modal_bad_text_block">
          <div class="theme_one_modal_bad_result_text w-100">
            <h5>{{ $t("Sorry_you_did_not_pass_the_test") }}</h5>
            <br />
            <h6>
              {{ $t("Your_score") }}: {{ percent }}%. Вы набрали {{ result }} из
              {{ max_score }} баллов.
              <br />
              <br />Проходной балл {{ passing_score }}
            </h6>
          </div>

          <div
            class="theme_one_modal_bad_result_btn pointer"
            @click="finished = false"
          >
            Закрыть
          </div>
        </div>
      </div>

      <div
        class="theme_one_modal_result_bad"
        v-if="finished && result >= quiz.passing_score"
      >
        <div class="theme_one_modal_bad_text_block">
          <div class="theme_one_modal_bad_result_text w-100">
            {{ $t("Congratulations!_You_have_passed_the_test") }}
            <br /><br />
            {{ $t("Your_score") }}: {{ percent }}%. Вы набрали {{ result }} из
            {{ max_score }} баллов. <br />Проходной балл {{ passing_score }}
          </div>

          <div
            class="theme_one_modal_bad_result_btn pointer"
            @click="finished = false"
          >
            Закрыть
          </div>
        </div>
      </div>

      <div class="theme_one_desc_title">
        {{ finished ? "Результаты теста: " : "" }}{{ quiz.title }}
      </div>
      <div class="theme_one_desc_test w-100">
        <!--        <div class="theme_one_modal_result_good" v-if="true">-->
        <!--          <div class="theme_one_modal_result_text">Ответ успешно сохранен</div>-->
        <!--          <div class="theme_one_modal_result_img">-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="theme_one_modal_result_bad" v-if="finished && result < quiz.passing_score">-->
        <!--          <div class="theme_one_modal_bad_text_block">-->
        <!--            <div class="theme_one_modal_bad_result_text">-->
        <!--              К сожалению, вы не прошли тестирование-->
        <!--              <br>-->
        <!--              Ваш результат: {{ result }} из {{ quiz.passing_score }}-->
        <!--            </div>-->
        <!--            <div class="theme_one_modal_bad_result_btn pointer" @click="reloadExercise">Попробовать заново</div>-->
        <!--          </div>-->
        <!--          <div class="theme_one_modal_result_img"></div>-->
        <!--        </div>-->
        <!--        <div class="theme_one_modal_result_good" v-if="finished && result >= quiz.passing_score">-->
        <!--          <div class="theme_one_modal_result_text">-->
        <!--            Поздравляем! Вы прошли тестирование.-->
        <!--            <br>-->
        <!--            Ваш результат: {{ result }} из {{ quiz.passing_score }}-->
        <!--          </div>-->
        <!--          <div class="theme_one_modal_result_img">-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="theme_one_modal_result_bad" v-if="error">
          <div class="theme_one_modal_bad_text_block">
            <div class="theme_one_modal_bad_result_text">
              {{ $t("An_error_has_occurred") }}
            </div>
            <div class="theme_one_modal_bad_result_btn">
              {{ $t("Try_again") }}
            </div>
          </div>
          <div class="theme_one_modal_result_img"></div>
        </div>

        <div v-else>
          <div
            v-if="currentQuestion && currentQuestion.text"
            class="theme_one_desc_text"
            v-html="currentQuestion.text"
          ></div>
          <div v-if="currentQuestion && currentQuestion.file">
            <audio
              v-if="currentQuestion.file.type === 'audio'"
              :src="apiRoot + currentQuestion.file.path"
              controls
            ></audio>
          </div>
          <div
            v-if="currentQuestion && currentQuestion.file"
            class="mb-5 w-100 text-center"
          >
            <a
              target="_blank"
              :href="apiRoot + currentQuestion.file.path"
              v-if="currentQuestion.file.type === 'file'"
              class="theme_one_desc_download"
            >
              {{ $t("Download_file") }}
            </a>
          </div>
          <div
            class="my_courses_list_radio_block"
            v-if="
              (currentQuestion && currentQuestion.type === 'TYPE_SINGLE') ||
              (currentQuestion && currentQuestion.type === 'TYPE_MULTIPLE')
            "
          >
            <div
              class="my_courses_radio_item"
              v-if="currentQuestion && currentQuestion.answers"
              v-for="answer in currentQuestion.answers"
            >
              <input
                :disabled="quizEnded"
                v-if="currentQuestion.type === 'TYPE_SINGLE'"
                v-model="selectedAnswer"
                :value="answer.id"
                :id="`answer${answer.id}`"
                type="radio"
                name="answer[]"
                class="my_courses_list_radio"
              />
              <input
                :disabled="quizEnded"
                v-if="currentQuestion.type === 'TYPE_MULTIPLE'"
                v-model="selectedAnswer"
                :value="answer.id"
                :id="`answer${answer.id}`"
                type="checkbox"
                name="answer[]"
                class="my_courses_list_radio"
              />
              <label
                :for="`answer${answer.id}`"
                class="my_courses_radio_label pointer pt-3"
                v-html="answer.text"
              ></label>
            </div>
          </div>
          <div v-else>
            <textarea
              :disabled="quizEnded"
              class="form-control mb-5"
              v-model="selectedAnswerRaw"
            />
          </div>
        </div>
      </div>
      <div class="theme_one_desc_btn" v-if="!finished">
        <!--        <a @click="saveAnswer" class="theme_one_desc_btn_back pointer">Сохранить ответ</a>-->
        <a
          v-if="!firstQuestion"
          @click="prev"
          class="theme_one_desc_btn_back pointer"
          >{{ $t("Back") }}</a
        >
        <a
          v-if="!lastQuestion"
          @click="next"
          class="theme_one_desc_btn_next pointer"
          >{{ $t("Further") }}</a
        >

        <a
          v-if="lastQuestion && quizEnded == false"
          @click="finish"
          class="theme_one_desc_btn_next pointer"
          >{{ $t("Finish") }}</a
        >
      </div>
      <div
        class="theme_one_desc_btn button-quiz-ended-wrapper"
        v-if="quizEnded"
      >
        <a
          v-if="quiz.mode === 'DEFAULT'"
          @click="showResults"
          class="button-quiz-ended"
          >Показать результаты</a
        >

        <a
          v-if="quiz.can_refresh && !(result >= quiz.passing_score)"
          @click="refreshQuiz"
          class="button-quiz-ended"
          >Пройти заново</a
        >
        <a
          v-if="result >= quiz.passing_score"
          class="button-quiz-ended"
          @click="$emit('goToNextModule')"
          >Следующая тема</a
        >
      </div>
    </div>
    <div class="theme_one_exercises_desc_text_container" v-else>
      <div class="theme_one_desc_test w-100" style="height: 400px">
        <div class="theme_one_modal_result_bad">
          <div class="theme_one_modal_bad_text_block">
            <div class="theme_one_modal_bad_result_text">
              {{ $t("Data_not_found") }}
            </div>
            <div class="theme_one_modal_bad_result_btn">
              {{ $t("Try_again") }}
            </div>
          </div>
          <div class="theme_one_modal_result_img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ExerciseMaterial",
  props: ["slug", "quizId"],
  data() {
    return {
      quizEnded: false,
      triesAvalible: false,
      savedAnswers: [],
      finished: false,
      results: [],
      result: 0,
      percent: 0,
      passing_score: 0,
      max_score: 0,
      success: false,
      error: false,
      quiz: false,
      apiRoot: window.API_ROOT,
      selectedAnswer: [],
      selectedAnswerRaw: null,
      currentQuestionId: 0,
    };
  },
  methods: {
    getQuiz(quizEnded = true, show = false) {
      if (this.quizId == null) return;

      let slug = this.slug;
      let quiz = this.quizId;
      this.$http
        .get(`${this.apiRoot}/api/course/${slug}/quiz/${quiz}`)
        .then((res) => {
          this.quiz = res.body;
          this.triesAvalible = this.quiz.user_tries > 0;
          if (quizEnded) {
            this.finished = show;
            if (show === false) {
              this.showResults(false);
            }

            let saved = [];

            for (let i = 0; i < res.body.questions.length; i++) {
              if (res.body.questions[i].user_answer.answer[0]) {
                saved.push(true);
              } else if (res.body.questions[i].user_answer.answer_raw) {
                saved.push(true);
              }
            }
            if (saved.length == res.body.questions.length) {
              this.quizEnded = true;
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    refreshQuiz() {
      this.quizEnded = false;
      this.triesAvalible = false;
      this.savedAnswers = [];
      this.finished = false;
      this.results = [];
      this.result = 0;
      this.percent = 0;
      this.passing_score = 0;
      this.max_score = 0;
      this.success = false;
      this.error = false;
      this.quiz = false;
      this.apiRoot = window.API_ROOT;
      this.selectedAnswer = [];
      this.selectedAnswerRaw = null;
      this.currentQuestionId = 0;
      let quiz = this.quizId;

      this.$http
        .post(`${this.apiRoot}/api/course/quiz/refresh/${quiz}`)
        .then((res) => {})
        .catch((err) => console.log(err))
        .finally(() => {
          this.getQuiz(false);
        });
    },
    saveAnswer() {
      if (this.quizEnded == false) {
        let params =
          this.selectedAnswerRaw !== null
            ? {
                answer: this.selectedAnswerRaw,
                answer_raw: this.selectedAnswerRaw,
                question_id: this.currentQuestion.id,
                quiz_id: this.quiz.id,
              }
            : {
                answer: this.selectedAnswer,
                question_id: this.currentQuestion.id,
                quiz_id: this.quiz.id,
              };
        this.$http
          .post(`${this.apiRoot}/api/course/quiz/answer/store`, params)
          .then((res) => {
            // if (res.body.message === 'saved')
            //   this.success = true
            // setTimeout(() => {
            //   this.success = false
            // }, 1000)
            // Vue.toastr({
            //   message: 'Success',
            //   description: 'Ответ сохранен',
            //   type: 'success'
            // })
          })
          .catch((err) => {
            // if (err.body.message === 'not_saved')
            //   this.error = true
            // setTimeout(() => {
            //   this.error = false
            // }, 1000)
            // Vue.toastr({
            //   message: 'Error',
            //   description: 'Не удалось сохранить ответ',
            //   type: 'error'
            // })
          })
          .finally(() => {
            this.getQuiz(false);
          });
      }
    },
    prev() {
      if (!this.currentQuestionId) return;
      if (this.selectedAnswerRaw !== "null" || this.selectedAnswer.length) {
        this.saveAnswer();
      }
      this.currentQuestionId--;
      this.error = false;
      this.success = false;
      this.selectedAnswer = [];
      this.selectedAnswerRaw = null;
      if (this.currentQuestion.user_answer) {
        if (this.currentQuestion.user_answer.answer) {
          if (this.currentQuestion.user_answer.answer.length == 1) {
            this.selectedAnswer = this.currentQuestion.user_answer.answer[0];
          } else {
            this.selectedAnswer = this.currentQuestion.user_answer.answer;
          }
        }
        if (this.currentQuestion.user_answer.answer_raw) {
          this.selectedAnswerRaw = this.currentQuestion.user_answer.answer_raw;
        }
      }
    },
    next() {
      if (typeof this.selectedAnswer == "object") {
        if (this.selectedAnswer.length == 0) {
          this.selectedAnswer = "";
        }
      }
      if (this.selectedAnswer || this.selectedAnswerRaw) {
        console.log(this.selectedAnswer);
        this.saveAnswer();
        this.currentQuestionId++;
        this.error = false;
        this.success = false;
        this.selectedAnswer = [];
        this.selectedAnswerRaw = null;
        return;
      }
      Vue.toastr({
        description: "Необходимо выбрать ответ",
        type: "error",
      });
    },
    showResults(finished = true) {
      this.$http
        .get(`${this.apiRoot}/api/course/quiz/get-user-answers/${this.quiz.id}`)
        .then((res) => {
          this.results = res.body.data;
          this.result = res.body.result;
          this.passing_score = res.body.passing_score;
          this.percent = res.body.percent;
          this.max_score = res.body.max_score;
          this.error = false;
          this.success = false;
          this.finished = finished;
        })
        .catch((err) => {
          Vue.toastr({
            message: "Error",
            description: "Не удалось завершить тест",
            type: "error",
          });
        })
        .finally(() => {});
    },
    finish() {
      if (typeof this.selectedAnswer == "object") {
        if (this.selectedAnswer.length == 0) {
          this.selectedAnswer = "";
        }
      }
      if (this.selectedAnswer || this.selectedAnswerRaw) {
        this.saveAnswer();
      } else {
        Vue.toastr({
          description: "Необходимо выбрать ответ",
          type: "error",
        });
        return;
      }

      if (this.quiz.mode == "DEFAULT" || this.quiz.mode == "ONLY_RAW_ANSWERS") {
        this.$http
          .get(
            `${this.apiRoot}/api/course/quiz/get-user-answers/${this.quiz.id}`,
          )
          .then((res) => {
            this.quizEnded = res.body.coin_credited;
            this.results = res.body.data;
            this.result = res.body.result;
            this.passing_score = res.body.passing_score;
            this.percent = res.body.percent;
            this.max_score = res.body.max_score;
            this.error = false;
            this.success = false;
            this.finished = true;
          })
          .catch((err) => {
            Vue.toastr({
              message: "Error",
              description: "Не удалось завершить тест",
              type: "error",
            });
          })
          .finally(() => {
            this.getQuiz(true, true);
            // this.quizEnded = true

            if (this.finished && this.result >= this.quiz.passing_score) {
              if (this.quizEnded == false) {
                this.$http
                  .post(`${this.apiRoot}/api/user/change-coins`, {
                    count: 1,
                    quiz_id: this.quiz.id,
                  })
                  .then(() => {});
              }
              this.$http
                .get(`${window.API_ROOT}/api/course/${this.$route.params.slug}`)
                .then((res) => {})
                .catch((err) => {
                  if (err.status === 404) {
                    this.$router.push({ name: "not-found" });
                  }
                  console.log(err);
                })
                .finally(() => {});
            }
          });
      } else {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.$route.params.slug,
            module: this.currentModuleId,
          },
        });
      }
      this.showResults();
    },
    reloadExercise() {
      this.$emit("reload", this.quiz.id);
    },
  },
  computed: {
    currentQuestion() {
      if (!this.quiz.questions) {
        return;
      }
      let item = this.quiz.questions.find((question, key) => {
        return key == this.currentQuestionId;
      });
      if (item.user_answer) {
        if (item.user_answer.answer) {
          if (item.user_answer.answer.length == 1) {
            this.selectedAnswer = item.user_answer.answer[0];
          } else {
            this.selectedAnswer = item.user_answer.answer;
          }
        }
        if (item.user_answer.answer_raw) {
          this.selectedAnswerRaw = item.user_answer.answer_raw;
        }
      }

      return item;
    },
    currentModule: function () {
      if (!(this.quiz.course && this.quiz.course.modules)) return false;
      return this.quiz.course.modules.find(
        (module) => module.id === this.quiz.module_id,
      );
    },
    currentModuleId: function () {
      if (!(this.quiz && this.quiz.owner)) return;
      return this.quiz.owner.course_chapter_id;
    },
    currentTheme: function () {
      if (!this.currentModule) return false;
      return this.currentModule.themes.find(
        (theme) => theme.id === this.quiz.theme_id,
      );
    },
    lastQuestion: function () {
      return this.currentQuestionId + 1 === this.quiz.questions.length;
    },
    firstQuestion: function () {
      return this.currentQuestionId == 0;
    },
  },
  mounted() {
    if (this.quizId != null) this.getQuiz();
  },
};
</script>

<style scoped>
.button-quiz-ended-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.button-quiz-ended {
  cursor: pointer;
  height: 52px;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #c460c0;
  border-radius: 42px;
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
</style>
