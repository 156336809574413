export default function Profile(attrs) {
  this.iin = attrs.iin || attrs.login ||  null;
  this.first_name = attrs.first_name ? attrs.first_name : null;
  this.last_name = attrs.last_name ? attrs.last_name : null;
  this.email = attrs.email ? attrs.email : null;
  this.phone = attrs.phone ? formatPhoneNumber(attrs.phone) : null;
  this.parent_name = attrs.parent_name ? attrs.parent_name : null;
  this.avatar = attrs.avatar ? attrs.avatar : null;
  this.parent_phone = attrs.parent_phone
    ? formatPhoneNumber(attrs.parent_phone)
    : null;
}

function formatPhoneNumber(phone) {
  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return (
      "+" +
      match[1] +
      " (" +
      match[2] +
      ") " +
      match[3] +
      " " +
      match[4] +
      "-" +
      match[5]
    );
  }
  return null;
}
