var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme_one_content_container"},[_c('router-link',{staticClass:"theme_one_content_container_btn_back",attrs:{"to":{
        name: 'course-module',
        params: { slug: _vm.slug, module: _vm.moduleId }
      }}},[_c('div',{staticClass:"theme_one_content_container_btn_text"},[_vm._v("Назад")])]),_c('div',{staticClass:"theme_one_plot"},_vm._l((_vm.module.themes),function(moduleTheme){return (_vm.module && _vm.module.themes)?_c('div',{class:{
        'theme_one_plot_theme_active': _vm.theme.id === moduleTheme.id,
        'theme_one_plot_theme': _vm.theme.id !== moduleTheme.id
      }},[(_vm.theme.id === moduleTheme.id)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"theme_one_plot_theme_active_label"},[_vm._v(_vm._s(moduleTheme.name))]),_vm._l((_vm.theme.materials),function(material,key){return _c('div',{staticClass:"theme_one_plot_theme_item",class:{
            active: key === _vm.materialId,
            video: material.type === 'video',
            audio: material.type === 'audio',
            document: material.type === 'document',
            presentation: material.type === 'presentation'
          },on:{"click":function($event){_vm.materialId = key;_vm.materialKey++}}},[_vm._v(_vm._s(material.title))])}),_vm._l((moduleTheme.quizzes),function(quiz){return _c('router-link',{staticClass:"theme_one_plot_theme_item exercise",attrs:{"to":{ name: 'course-exercise', params: { slug: _vm.slug, quiz: quiz.id } }}},[_vm._v(" "+_vm._s(quiz.title)+" ")])})],2):_c('span',[_vm._v(_vm._s(moduleTheme.name))])]):_vm._e()}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }